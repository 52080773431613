/* eslint-disable react/require-default-props */

import { IconCircleMS, IconTaskAltMS } from '../../../constants/icons';
import { Container, ProgressBar, Icon, Line } from './styles';

interface ProgressBarProps {
  children: JSX.Element;
  complete: boolean;
  lastAccordion?: boolean;
}

export function WorkOrderProgressBar({
  children,
  complete,
  lastAccordion = false,
}: ProgressBarProps): JSX.Element {
  return (
    <Container>
      <ProgressBar>
        <Icon complete={complete ? 'true' : 'false'}>
          {complete ? IconTaskAltMS : IconCircleMS}
        </Icon>
        {!lastAccordion && <Line complete={complete ? 'true' : 'false'} />}
      </ProgressBar>
      {children}
    </Container>
  );
}
