import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContainerButtons = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  justifyContent: 'flex-end',
  marginTop: 16,
}));

export const Iframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: 800,
  scrollbarWidth: 'thin',
  scrollbarColor: 'black transparent',
  border: 'none',
  borderRadius: 4,

  [theme.breakpoints.down('xl')]: {
    height: 750,
  },
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 260,
  },
}));
