import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  ApproveWorkOrderProps,
  CreateWorkOrderProps,
  DetailProps,
  ForceFactorsProps,
  GetAllWorkOrderProps,
  GetArchivedWorkOrdersProps,
  GetInStatusWorkOrdersProps,
  GetListWorkOrdersProps,
  GetTopWorkOrdersProps,
  GetWorkOrderProps,
} from './types';

const createWorkOrder = (data: FormData): Promise<CreateWorkOrderProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const editWorkOrder = (
  id: number,
  data: FormData,
  updateCoordinate = false
): Promise<CreateWorkOrderProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}?force_coordinate_event=${updateCoordinate}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getAllWorkOrders = (search: string): Promise<GetAllWorkOrderProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders?search_string=${search}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getTopWorkOrders = (): Promise<GetTopWorkOrdersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders/top`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getInStatusWorkOrders = (
  status: number,
  page: number,
  size: number,
  sortType: 'asc' | 'desc'
): Promise<GetInStatusWorkOrdersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/in-status/${status}?size=${size}&page=${page}&sort=${sortType}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getListWorkOrders = (
  page: number,
  size: number,
  search: string
): Promise<GetListWorkOrdersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/list?page=${page}&size=${size}&search_string=${search}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getArchivedWorkOrders = (
  page: number,
  size: number
): Promise<GetArchivedWorkOrdersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/archived?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const getWorkOrder = (id: number): Promise<GetWorkOrderProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const changeStatus = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/status`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const previousStatus = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/previous-status`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

const sendToPept = (id: number, observation: string): Promise<DetailProps> => {
  const data = JSON.stringify({ observation });
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/pept`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const addGoogleMapsImagesWithPins = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/google-maps-images`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const approveWorkOrder = (
  id: number,
  data: ApproveWorkOrderProps
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/approve`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const cancelWorkOrder = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/cancel`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const uploadFacadeImage = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/facade-image`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const exportSample = (id: number): Promise<Response> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/samples-xlsx`,
    requestOptions
  ).then((response) => response);
};

export const sendGraphs = (
  id: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/report-graphs`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const generateReportPDF = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/generate-report-pdf`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getReportPDF = (id: number): Promise<Uint8Array> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/report-pdf`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Não foi possível carregar o relatório.');
      }
      return response.arrayBuffer();
    })
    .then((buffer) => new Uint8Array(buffer))
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const generateSamplePDF = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/generate-samples-report-pdf`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getSamplePDF = (id: number): Promise<Uint8Array> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/samples-report-pdf`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Não foi possível carregar o relatório da amostra');
      }
      return response.arrayBuffer();
    })
    .then((buffer) => new Uint8Array(buffer))
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const sendForceFactors = (
  id: number,
  data: ForceFactorsProps
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(`${BaseUrl}/api/V1/work-orders/${id}/avm-info`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateSupplyFactor = (
  id: number,
  factor: number
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/supply-factor?supply_factor=${factor}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export default {
  addGoogleMapsImagesWithPins,
  approveWorkOrder,
  cancelWorkOrder,
  changeStatus,
  createWorkOrder,
  editWorkOrder,
  exportSample,
  generateReportPDF,
  generateSamplePDF,
  getAllWorkOrders,
  getTopWorkOrders,
  getInStatusWorkOrders,
  getListWorkOrders,
  getArchivedWorkOrders,
  getReportPDF,
  getSamplePDF,
  getWorkOrder,
  previousStatus,
  sendGraphs,
  sendToPept,
  uploadFacadeImage,
  sendForceFactors,
  updateSupplyFactor,
};
