import { ButtonBox } from '../../../components/UI/Box';
import { FilledButton } from '../../../components/UI/Button';
import { IconCloseMS } from '../../../constants/icons';
import { Constants } from '../../../constants/sampleCreation';
import {
  CloseIcon,
  DialogBox,
  DialogTypography,
  FlexBoxCenter,
  StyledDialog,
} from './styles';

interface AddressDialogProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

export function AddressDialog({
  show,
  setShow,
}: AddressDialogProps): JSX.Element {
  return (
    <StyledDialog
      open={show}
      onClose={() => setShow(false)}
      aria-labelledby="address dialog"
    >
      <DialogBox>
        <ButtonBox>
          <CloseIcon onClick={() => setShow(false)}>{IconCloseMS}</CloseIcon>
        </ButtonBox>
        <DialogTypography>{Constants.addressText}</DialogTypography>
        <FlexBoxCenter>
          <FilledButton onClick={() => setShow(false)}>
            {Constants.confirmAddress}
          </FilledButton>
        </FlexBoxCenter>
      </DialogBox>
    </StyledDialog>
  );
}
