import { ChangeEvent, DragEvent, useContext, useState } from 'react';

import { workOrderAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { SubmitBox } from '../../../components/UI/Box';
import { FilledButton, OutlinedButton } from '../../../components/UI/Button';
import { StyledDialog } from '../../../components/UI/Dialog';
import { DialogTitle } from '../../../components/UI/Typography';
import { IconAddPhotoAlternateMS, IconCloseMS } from '../../../constants/icons';
import { Constants } from '../../../constants/inspection';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import useGeneral from '../../../hooks/useGeneral';
import useUploadFile from '../../../hooks/useUploadFile';
import {
  DialogContainer,
  DeleteCircleButton,
  ImagePreview,
  InsideBox,
  UploadAndPreviewContainer,
  Upload,
  UploadIcon,
  UploadSubtitle,
  UploadTitle,
} from './styles';

interface FacadeModalProps {
  osId: number;
  updatePropertyData: () => Promise<void>;
}

export function FacadeModal({
  osId,
  updatePropertyData,
}: FacadeModalProps): JSX.Element {
  const [facadePicture, setFacadePicture] = useState<File>();
  const [imagePreview, setImagePreview] = useState('');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const dialog = useGeneral();
  const { dragActive, handleDrag, handleDrop, handleFileUpload } =
    useUploadFile();

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): void => {
    const files = handleFileUpload(e);
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        setImagePreview(url);
      };
      reader.readAsDataURL(files[0]);
      setFacadePicture(files[0]);
    }
  };

  const dropFile = (e: DragEvent<HTMLDivElement>): void => {
    const files = handleDrop(e);
    if (files) {
      const { files } = e.dataTransfer;
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        setImagePreview(url);
      };
      reader.readAsDataURL(files[0]);
      setFacadePicture(files[0]);
    }
  };

  const handleCancel = (): void => {
    setFacadePicture(undefined);
    setImagePreview('');
    dialog.handleClose();
  };

  const handleSubmitPhotos = async (): Promise<void> => {
    if (!facadePicture) {
      setSnackbarMessage('O upload da foto de fachada é obrigatório');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const formData = new FormData();
    formData.append('image', facadePicture);
    try {
      const response = await workOrderAPI.uploadFacadeImage(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage('Imagem atualizada');
      setErrorMessage(false);
      setOpenSnackbar(true);
      updatePropertyData();

      handleCancel();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDeletePhoto = (): void => {
    setImagePreview('');
    setFacadePicture(undefined);
  };

  return (
    <>
      <OutlinedButton model="main" onClick={dialog.handleOpen}>
        {Constants.addFacade}
      </OutlinedButton>
      <StyledDialog open={dialog.open} onClose={dialog.handleOpen}>
        <DialogContainer>
          <DialogTitle>{IconAddPhotoAlternateMS} Adicionar foto</DialogTitle>
          <UploadAndPreviewContainer>
            <Upload
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={dropFile}
            >
              <label htmlFor="uploadPhotos">
                <input
                  accept="image/*"
                  multiple={false}
                  id="uploadPhotos"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFile(e)}
                />
                {!dragActive ? (
                  <InsideBox>
                    <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                    <UploadTitle>selecione a foto</UploadTitle>
                    <UploadSubtitle>(JPG, GIF e PNG somente)</UploadSubtitle>
                  </InsideBox>
                ) : (
                  <UploadTitle>solte o arquivo aqui</UploadTitle>
                )}
              </label>
            </Upload>
            {imagePreview && (
              <ImagePreview image={imagePreview}>
                <DeleteCircleButton onClick={() => handleDeletePhoto()}>
                  {IconCloseMS}
                </DeleteCircleButton>
              </ImagePreview>
            )}
          </UploadAndPreviewContainer>
          <SubmitBox>
            <OutlinedButton onClick={handleCancel}>cancelar</OutlinedButton>
            <FilledButton onClick={handleSubmitPhotos}>salvar</FilledButton>
          </SubmitBox>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
