import { useEffect } from 'react';

import {
  Status,
  convertStatus,
  convertStatusUrl,
} from '../../../api/enumerations';
import {
  GetAllWorkOrderData,
  GetTopWorkOrdersData,
  WorkOrderData,
} from '../../../api/workOrders/types';
import { Column } from './Column';
import { PipelineContainer } from './styles';

interface PipelineViewProps {
  workOrders: GetAllWorkOrderData | GetTopWorkOrdersData | undefined;
  pipeLoading: boolean;
  isSearching: boolean;
  setAllowSearch: (value: boolean) => void;
}

export default function PipelineView({
  workOrders,
  pipeLoading,
  isSearching,
  setAllowSearch,
}: PipelineViewProps): JSX.Element {
  const pipelineColumn = [
    {
      id: 'entrance',
      name: convertStatus(Status.ENTRANCE),
      workOrders:
        workOrders?.[Status.ENTRANCE] && 'items' in workOrders[Status.ENTRANCE]
          ? workOrders?.[Status.ENTRANCE]?.items
          : (workOrders?.[Status.ENTRANCE] as WorkOrderData[]),
      link: convertStatusUrl(Status.ENTRANCE),
      status: Status.ENTRANCE,
      totalRecords:
        workOrders?.[Status.ENTRANCE] && 'items' in workOrders[Status.ENTRANCE]
          ? workOrders?.[Status.ENTRANCE]?.total_records
          : undefined,
    },
    {
      id: 'pept',
      name: convertStatus(Status.PEPT),
      workOrders:
        workOrders?.[Status.PEPT] && 'items' in workOrders[Status.PEPT]
          ? workOrders?.[Status.PEPT]?.items
          : (workOrders?.[Status.PEPT] as WorkOrderData[]),
      link: convertStatusUrl(Status.PEPT),
      status: Status.PEPT,
      totalRecords:
        workOrders?.[Status.PEPT] && 'items' in workOrders[Status.PEPT]
          ? workOrders?.[Status.PEPT]?.total_records
          : undefined,
    },
    {
      id: 'schedule',
      name: convertStatus(Status.SCHEDULE),
      workOrders:
        workOrders?.[Status.SCHEDULE] && 'items' in workOrders[Status.SCHEDULE]
          ? workOrders?.[Status.SCHEDULE]?.items
          : (workOrders?.[Status.SCHEDULE] as WorkOrderData[]),
      link: convertStatusUrl(Status.SCHEDULE),
      status: Status.SCHEDULE,
      totalRecords:
        workOrders?.[Status.SCHEDULE] && 'items' in workOrders[Status.SCHEDULE]
          ? workOrders?.[Status.SCHEDULE]?.total_records
          : undefined,
    },
    {
      id: 'inspection',
      name: convertStatus(Status.INSPECTION),
      workOrders:
        workOrders?.[Status.INSPECTION] &&
        'items' in workOrders[Status.INSPECTION]
          ? workOrders?.[Status.INSPECTION]?.items
          : (workOrders?.[Status.INSPECTION] as WorkOrderData[]),
      link: convertStatusUrl(Status.INSPECTION),
      status: Status.INSPECTION,
      totalRecords:
        workOrders?.[Status.INSPECTION] &&
        'items' in workOrders[Status.INSPECTION]
          ? workOrders?.[Status.INSPECTION]?.total_records
          : undefined,
    },
    {
      id: 'sample-creation',
      name: convertStatus(Status.SAMPLE_CREATION),
      workOrders:
        workOrders?.[Status.SAMPLE_CREATION] &&
        'items' in workOrders[Status.SAMPLE_CREATION]
          ? workOrders?.[Status.SAMPLE_CREATION]?.items
          : (workOrders?.[Status.SAMPLE_CREATION] as WorkOrderData[]),
      link: convertStatusUrl(Status.SAMPLE_CREATION),
      status: Status.SAMPLE_CREATION,
      totalRecords:
        workOrders?.[Status.SAMPLE_CREATION] &&
        'items' in workOrders[Status.SAMPLE_CREATION]
          ? workOrders?.[Status.SAMPLE_CREATION]?.total_records
          : undefined,
    },
    {
      id: 'calculation',
      name: convertStatus(Status.CALCULATION),
      workOrders:
        workOrders?.[Status.CALCULATION] &&
        'items' in workOrders[Status.CALCULATION]
          ? workOrders?.[Status.CALCULATION]?.items
          : (workOrders?.[Status.CALCULATION] as WorkOrderData[]),
      link: convertStatusUrl(Status.CALCULATION),
      status: Status.CALCULATION,
      totalRecords:
        workOrders?.[Status.CALCULATION] &&
        'items' in workOrders[Status.CALCULATION]
          ? workOrders?.[Status.CALCULATION]?.total_records
          : undefined,
    },
    {
      id: 'report',
      name: convertStatus(Status.REPORT),
      workOrders:
        workOrders?.[Status.REPORT] && 'items' in workOrders[Status.REPORT]
          ? workOrders?.[Status.REPORT]?.items
          : (workOrders?.[Status.REPORT] as WorkOrderData[]),
      link: convertStatusUrl(Status.REPORT),
      status: Status.REPORT,
      totalRecords:
        workOrders?.[Status.REPORT] && 'items' in workOrders[Status.REPORT]
          ? workOrders?.[Status.REPORT]?.total_records
          : undefined,
    },
    {
      id: 'revision',
      name: convertStatus(Status.REVISION),
      workOrders:
        workOrders?.[Status.REVISION] && 'items' in workOrders[Status.REVISION]
          ? workOrders?.[Status.REVISION]?.items
          : (workOrders?.[Status.REVISION] as WorkOrderData[]),
      link: convertStatusUrl(Status.REVISION),
      status: Status.REVISION,
      totalRecords:
        workOrders?.[Status.REVISION] && 'items' in workOrders[Status.REVISION]
          ? workOrders?.[Status.REVISION]?.total_records
          : undefined,
    },
  ];

  useEffect(() => {
    setAllowSearch(true);
  }, [setAllowSearch]);

  return (
    <PipelineContainer>
      {pipelineColumn.map((column) => (
        <Column
          key={column.name}
          columnData={column}
          pipeLoading={pipeLoading}
          isSearching={isSearching}
        />
      ))}
    </PipelineContainer>
  );
}
