import { useContext } from 'react';

import { GlobalContext } from '../context/global';

interface UseSnackbarHook {
  handleSnackbar: (message: string, isError: boolean) => void;
}

export default function useSnackbar(): UseSnackbarHook {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleSnackbar = (message: string, isError: boolean): void => {
    setSnackbarMessage(message);
    setErrorMessage(isError);
    setOpenSnackbar(true);
  };

  return {
    handleSnackbar,
  };
}
