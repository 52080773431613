/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box, CircularProgress } from '@mui/material';
import { driver } from 'driver.js';
import { useEffect, useState } from 'react';

import { StepTour } from '../../api/enumerations';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { WorkOrderProgressBar } from '../../components/Sections/ProgressBar';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  ButtonsSpace,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import { BackButton, CancelOSButton } from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import { SectionTitle, StyledInput } from '../../components/UI/Typography';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCancelMS,
  IconEditNoteMS,
  IconLocationCityMS,
  IconLocationOnMS,
} from '../../constants/icons';
import { Constants } from '../../constants/pept';
import { driverConfig } from '../../helpers/driver/config';
import { peptSteps } from '../../helpers/driver/steps';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import usePept from './hooks';
import { StyledButton } from './styles';

export default function Pept(): JSX.Element {
  const { navigateHome, osId } = useGeneral();
  const { handleStatus, setLoadingApprove, loadingApprove } = useChangeStatus();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const { propertyData, loadingPage } = usePept();

  useEffect(() => {
    if (expandOne && expandTwo && expandThree) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, expandThree]);

  const {
    tourSelection,
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    setToBeContinued,
    setTourOn,
    isTourOn,
    setTourSelection,
    driveIsActive,
  } = useTour();

  const driverObj = driver({
    ...driverConfig,
    steps: peptSteps,
    onNextClick: () => {
      if (driverObj.isLastStep()) {
        setTourCompletion({
          ...tourCompletion,
          pept: { complete: true },
          lastStepSeen: StepTour.PEPTFINISH,
        });
        setTutorialStep(StepTour.PEPTFINISH);
        setToBeContinued(false);
        setTourOn(true);
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      setTourSelection(false);
      setToBeContinued(false);
      driverObj?.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      driverObj?.destroy();
    },
  });

  const [tourHasStarted, setTourHasStarted] = useState(false);

  useEffect(() => {
    if (!tourCompletion.pept.complete && !tourCompletion.skipTour.pept) {
      setTutorialStep(StepTour.PEPTSTART);
      if (!driveIsActive && !tourHasStarted) {
        setTourOn(true);
        setTourHasStarted(true);
      } else if (driveIsActive && !isTourOn) {
        driverObj.drive();
      }
    } else if (tourSelection && driveIsActive && !isTourOn) {
      setTutorialStep(StepTour.PEPTSTART);
      setTimeout(() => {
        driverObj.drive();
      }, 250);
    }
  }, [tourSelection, driveIsActive, isTourOn]);

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.pept}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDialog
                  text={Constants.cancelOsText}
                  button={
                    <CancelOSButton>
                      {IconCancelMS}
                      {Constants.cancelOs}
                    </CancelOSButton>
                  }
                  model="error"
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <AttachFilesDialog propertyData={propertyData} osId={osId} />
            </FlexSpaceBetweenBox>
            <Box id="reason">
              <SectionTitle sx={{ margin: '20px 0' }}>
                {IconEditNoteMS}
                {Constants.reason}
              </SectionTitle>
              <StyledInput className="reason">
                {propertyData?.observation && propertyData.observation}
              </StyledInput>
            </Box>
            <WorkOrderProgressBar complete>
              <>
                <ClientData propertyData={propertyData} />
                <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              </>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete lastAccordion>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
            </WorkOrderProgressBar>
            <SubmitBox>
              <ButtonsSpace sx={{ width: '100%' }}>
                <Box />
                <ConfirmationDialog
                  id="approve-btn"
                  loading={loadingApprove}
                  text={Constants.changeStatus.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  button={<StyledButton>{Constants.approve}</StyledButton>}
                  modalCallback={() => {
                    setLoadingApprove(true);
                    handleStatus(osId, propertyData?.reference_number);
                  }}
                />
              </ButtonsSpace>
            </SubmitBox>
          </>
        )}
      </BoxContainer>
    </GridContainer>
  );
}
