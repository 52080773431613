import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
  },
}));

export const DialogBox = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '200px',
  padding: '40px',
}));

export const CloseIcon = styled(IconButton)(({ theme }) => ({
  margin: '-30px -30px 0',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
}));

export const DialogTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: '600',
  color: '#595959',
}));

export const FlexBoxCenter = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: '32px',
}));
