/* eslint-disable react-hooks/exhaustive-deps */
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Box, LinearProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { workOrderAPI } from '../../../api';
import { IconDownloadMS } from '../../../constants/icons';
import { Constants } from '../../../constants/revision';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import useSnackbar from '../../../hooks/useSnackbar';
import { useTour } from '../../../hooks/useTour';
import { ExportSample } from '../../ExportSample';
import { ContainerButtons, DownloadButton, Iframe } from './styles';

interface PdfViewerProps {
  osId: number;
  samplePdf: string | null;
}

export function PdfViewer({ osId, samplePdf }: PdfViewerProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [reportFile, setReportFile] = useState<File>();
  const [reportURL, setReportURL] = useState('');

  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadFile, handleDownloadPdf, createPdfFile } = useGeneral();
  const { handleSnackbar } = useSnackbar();
  const { setEnableTour } = useTour();

  const getReportPDF = useCallback(async () => {
    setLoading(true);
    try {
      const resReportPdf = await workOrderAPI.getReportPDF(osId);

      const reportPdf = createPdfFile(resReportPdf, 'report-file');

      setReportURL(reportPdf.url);
      setEnableTour(reportPdf.file !== undefined);
      setReportFile(reportPdf.file);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [osId]);

  useEffect(() => {
    getReportPDF();
  }, []);

  if (loading) return <LinearProgress />;

  return (
    <Box>
      {!reportFile ? (
        <p>não foi possível carregar o relatório</p>
      ) : (
        <>
          <Iframe src={reportURL} title="relatório" />
          <ContainerButtons>
            <ExportSample osId={osId} />
            <DownloadButton
              disabled={!samplePdf}
              onClick={() =>
                samplePdf && handleDownloadFile(samplePdf, 'sample-file')
              }
            >
              {IconDownloadMS}
              {Constants.downloadSamplePDF}
            </DownloadButton>
            <DownloadButton
              id="report-pdf-button"
              disabled={!reportFile}
              onClick={() => handleDownloadPdf(reportFile)}
            >
              {IconDownloadMS}
              {Constants.downloadReport}
            </DownloadButton>
          </ContainerButtons>
        </>
      )}
    </Box>
  );
}
