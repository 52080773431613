import { Grid } from '@mui/material';
import { ChangeEvent } from 'react';

import { convertPropertyType } from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import {
  selectConcept,
  selectRegistrationUf,
} from '../../../constants/selectOptions';
import {
  CustomTextField,
  NumericTextField,
  SelectTextField,
} from '../../CustomInput';
import { WorkOrderFile } from '../../Sections/WorkOrderFile';
import { InputTitle, StyledInput } from '../../UI/Typography';
import { Constants } from './constants';

interface PropertyDataEditProps {
  propertyData: WorkOrderData;
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void>;
  registerFileName: string;
  iptuFileName: string;
  downloadIptu: string;
  downloadRegister: string;
  registerNumber: string;
  setRegisterNumber: (value: string) => void;
  concept: number;
  setConcept: (value: number) => void;
  zone: number;
  setZone: (value: number) => void;
  judicialDistrict: string;
  setJudicialDistrict: (value: string) => void;
  registrationUf: number;
  setRegistrationUf: (value: number) => void;
}

export function PropertyDataEdit({
  propertyData,
  handleFileUpload,
  registerFileName,
  iptuFileName,
  downloadIptu,
  downloadRegister,
  registerNumber,
  setRegisterNumber,
  concept,
  setConcept,
  zone,
  setZone,
  judicialDistrict,
  setJudicialDistrict,
  registrationUf,
  setRegistrationUf,
}: PropertyDataEditProps): JSX.Element {
  return (
    <>
      <WorkOrderFile
        downloadRegister={downloadRegister}
        downloadIptu={downloadIptu}
        registerFileName={registerFileName}
        iptuFileName={iptuFileName}
        handleFileUpload={handleFileUpload}
      />
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <CustomTextField
            required
            id="register-number"
            label="nº da matrícula"
            value={registerNumber}
            setValue={setRegisterNumber}
          />
        </Grid>
        <Grid item xs={4}>
          <InputTitle>{Constants.propertyType}</InputTitle>
          <StyledInput>
            {convertPropertyType(propertyData.real_estate_kind)}
          </StyledInput>
        </Grid>
        <Grid item xs={4}>
          <SelectTextField
            id="concept"
            label="conceito do espaço"
            value={concept}
            setValue={setConcept}
            selectOptions={selectConcept()}
          />
        </Grid>
        <Grid item xs={4}>
          <NumericTextField
            id="zone"
            label="ofício/zona"
            suffix=""
            maxLength={12}
            value={zone}
            setValue={setZone}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            required
            id="judicial-district"
            label="comarca"
            value={judicialDistrict}
            setValue={setJudicialDistrict}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectTextField
            id="registration-uf"
            label="UF"
            value={registrationUf}
            setValue={setRegistrationUf}
            selectOptions={selectRegistrationUf()}
          />
        </Grid>
      </Grid>
    </>
  );
}
