export const Constants = {
  dataTitle: 'Dados e informações cadastrais',
  changePassword: 'Redefinir senha',
  deleteAccount: 'Deletar conta',
  deleteAccountText: 'deletar esta conta',
  downloadData: 'Baixar meus dados',
  privacyPolicy: 'Política de privacidade',
  termsUse: 'Termos de uso',
  cancel: 'cancelar',
  save: 'salvar',
  confirmationText: 'Você gostaria de **?',
  no: 'Não',
  yes: 'Sim',
  confirmationWarning: 'Atenção! Esta ação não poderá ser desfeita.',
  plansBilling: 'Assinatura e cobrança',
  plan: 'Plano de Assinatura',
  description: 'Descrição',
  price: 'Valor do plano',
  frequency: 'Periodicidade',
  renewall: 'Renovação',
  status: 'Status',
  changePlan: 'Alterar plano',
  signPlan: 'Assinar plano',
  billing: 'Detalhes de cobrança',
  beggining: 'Data de início',
  lastPayment: 'Último pagamento',
  nextPayment: 'Próximo pagamento',
  paymentMethod: 'Método de pagamento',
  changeCard: 'Alterar cartão',
  paymentHistory: 'Histórico de pagamento',
  value: 'Valor',
  dueDate: 'Data do vencimento',
  paymentDate: 'Data do pagamento',
  cancelPlan: 'cancelar assinatura',
  cancelPlanText: 'cancelar sua assinatura atual',
  customize: 'PERSONALIZAR RELATÓRIO / PLATAFORMA',
  logo: 'Logo',
  color: 'Cor',
  primary: 'primária',
  dragText: 'solte os arquivos aqui',
  uploadText: 'arraste e solte a imagem para adicioná-la',
  fileType: '(JPG e PNG somente)',
  searchLogo: 'buscar logo',
  changeLogo: 'alterar logo',
  logoObs:
    'Ao alterar a imagem do logotipo você atualizará a imagem na plataforma e no relatório.',
  colorObs:
    'Ao alterar a cor primária, apenas a cor do relatório será alterada.',
  deadlineTitle: 'Prazo para entrega de documentos da OS',
  deadline: 'prazo',
  deadlineWarning: 'alerta para fim do prazo',
  updateAll: 'Atualizar todas as Ordens de Serviço',
  updateNew: 'Atualizar apenas Ordens de Serviços novas',
};
