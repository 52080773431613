/* eslint-disable react-hooks/exhaustive-deps */
import { Box, LinearProgress } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { workOrderAPI } from '../../../../api';
import { Status, StatusCode } from '../../../../api/enumerations';
import { WorkOrderData } from '../../../../api/workOrders/types';
import { IconNorthdMS, IconSouthdMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { Card } from './Card';
import {
  PipelineBox,
  PipelinePaper,
  PipelineTitle,
  SortIcons,
  StyledSkeleton,
} from './styles';

interface ColumnProps {
  columnData: {
    id: string;
    name: string;
    workOrders: WorkOrderData[] | undefined;
    link: string;
    status: Status;
    totalRecords: number | undefined;
  };
  pipeLoading: boolean;
  isSearching: boolean;
}

export function Column({
  columnData,
  pipeLoading,
  isSearching,
}: ColumnProps): JSX.Element {
  const [loadingMore, setLoadingMore] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [workOrders, setWorkOrders] = useState<WorkOrderData[]>([]);
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
  const [applySort, setApplySort] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const currentStatus = columnData.status;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getWorkOrderInStatus = async (): Promise<void> => {
    if (page === lastCalledPage) {
      setApplySort(false);
      setSortLoading(false);
      return;
    }

    try {
      const workOrdersPerPage = 10;
      const response = await workOrderAPI.getInStatusWorkOrders(
        currentStatus,
        page,
        workOrdersPerPage,
        sortType
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        const updatedData = [...workOrders, ...response.data];
        setWorkOrders(updatedData);
        setLastCalledPage(page);

        if (response.detail.total_pages && response.detail.total_pages > page) {
          setPage(page + 1);
        }

        setApplySort(false);
        setLoadingMore(false);
        setSortLoading(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingMore(false);
      setSortLoading(false);
      setApplySort(false);
    }
  };

  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (loadingMore) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (
        columnData.workOrders?.length === 10 &&
        columnData.totalRecords &&
        columnData.totalRecords > 10 &&
        entries[0].isIntersecting &&
        page > lastCalledPage
      ) {
        setLoadingMore(true);
        getWorkOrderInStatus();
      }
    });

    if (node) observer.current.observe(node);
  };

  useEffect(() => {
    if (columnData.workOrders) {
      if (columnData.workOrders.length > 0) {
        setWorkOrders(columnData.workOrders);
      }
    } else {
      setWorkOrders([]);
    }
    setPage(2);
    setLastCalledPage(0);
    setSortType('desc');
  }, [columnData]);

  const handleSort = (): void => {
    setSortType((prevType) => (prevType === 'desc' ? 'asc' : 'desc'));
    setPage(1);
    setLastCalledPage(0);
    setWorkOrders([]);
    setApplySort(true);
  };

  useEffect(() => {
    if (applySort) {
      getWorkOrderInStatus();
      setSortLoading(true);
    }
  }, [applySort]);

  const handleTitle = (): string => {
    const { totalRecords, name, workOrders } = columnData;
    const workOrderRecords = workOrders?.length || 0;

    const recordCount =
      totalRecords !== undefined ? totalRecords : workOrderRecords;

    return `${name} (${!pipeLoading && !sortLoading ? recordCount : 0})`;
  };

  return (
    <PipelineBox id={columnData.id}>
      <PipelineTitle
        onClick={isSearching ? undefined : handleSort}
        disableTouchRipple
        sx={{ cursor: isSearching ? 'default' : 'pointer' }}
      >
        {handleTitle()}
        <SortIcons selectedchild={isSearching ? 'none' : sortType}>
          {IconSouthdMS}
          {IconNorthdMS}
        </SortIcons>
      </PipelineTitle>
      <PipelinePaper>
        {pipeLoading || sortLoading
          ? [1, 2, 3, 4, 5].map((item) => (
              <StyledSkeleton
                key={item}
                variant="rectangular"
                animation="pulse"
              />
            ))
          : workOrders.map((item: WorkOrderData, index) => {
              const lastElement = workOrders.length === index + 1;
              return (
                <Link
                  to={`/home/property/${item?.id}/${columnData.link}`}
                  key={item.id}
                  ref={lastElement && !isSearching ? lastElementRef : undefined}
                  style={{ textDecoration: 'none' }}
                >
                  <Card cardData={item} />
                </Link>
              );
            })}
        {loadingMore && (
          <Box m="12px">
            <LinearProgress />
          </Box>
        )}
      </PipelinePaper>
    </PipelineBox>
  );
}
