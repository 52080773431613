import { useState } from 'react';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';

interface GeneralHook {
  open: boolean;
  osId: number;
  params: Params;
  handleOpen(): void;
  handleClose(): void;
  navigateHome(): void;
  checkPathname(value: string): boolean;
  handleDownloadFile: (
    value: string,
    name: string,
    blankPage?: boolean
  ) => void;
  handleDownloadPdf: (value: File) => void;
  handleDownloadXlsx: (value: Response) => void;
  createPdfFile: (
    pdfData: Uint8Array,
    filename: string
  ) => { url: string; file: File | undefined };
}

export default function useGeneral(): GeneralHook {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const osId = parseInt(id as string, 10);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);
  const navigateHome = (): void => navigate('/home');

  const checkPathname = (value: string): boolean => {
    return location.pathname.includes(value);
  };

  const handleDownloadFile = (
    dataLink: string,
    name: string,
    blankPage?: boolean
  ): void => {
    const linkSource = dataLink;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = name;
    if (blankPage) {
      window.open(linkSource);
    } else {
      downloadLink.click();
    }
  };

  const handleDownloadPdf = (pdf: File): void => {
    if (!pdf.name) return;
    const temp = document.createElement('a');
    document.body.appendChild(temp);

    const url = window.URL.createObjectURL(pdf);
    temp.href = url;
    temp.download = pdf.name;
    temp.click();
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadXlsx = (data: Response): void => {
    const contentDisposition = data.headers
      .get('Content-Disposition')
      ?.split('filename=')[1];
    const filename = contentDisposition || 'file.xlsx';
    data.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      handleDownloadFile(url, filename);
      window.URL.revokeObjectURL(url);
    });
  };

  const createPdfFile = (
    pdfData: Uint8Array,
    filename: string
  ): { url: string; file: File | undefined } => {
    const binaryData = new Blob([pdfData], {
      type: 'application/pdf',
    });
    const url = URL.createObjectURL(binaryData);
    const pdfFile = binaryData
      ? new File([binaryData], filename, {
          type: binaryData.type,
        })
      : undefined;
    return {
      url,
      file: pdfFile,
    };
  };

  return {
    open,
    osId,
    params,
    handleOpen,
    handleClose,
    navigateHome,
    checkPathname,
    handleDownloadFile,
    handleDownloadPdf,
    handleDownloadXlsx,
    createPdfFile,
  };
}
