/* eslint-disable react/require-default-props */
import { IconExpandLessMS, IconExpandMoreMS } from '../../constants/icons';
import { AccordionButton } from './styles';

interface ToogleAccordionProps {
  expand: boolean;
  toogle: () => void;
}

export function ToogleAccordion({
  expand,
  toogle,
}: ToogleAccordionProps): JSX.Element {
  return (
    <AccordionButton onClick={toogle} disableTouchRipple>
      {expand ? IconExpandLessMS : IconExpandMoreMS}
      {expand ? 'recolher todos' : 'expandir todos'}
    </AccordionButton>
  );
}
