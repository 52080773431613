import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccordionButton = styled(ButtonBase)(({ theme }) => ({
  margin: '10px 0 10px',
  fontSize: '16px',
  width: '100%',
  justifyContent: 'flex-end',
  '& span': {
    color: theme.palette.primary.main,
    fontSize: '32px',
  },
  '&:hover': {
    fontWeight: '600',
  },
}));
