import { Box, Divider, Grid, Stack, Switch, Typography } from '@mui/material';
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { companyAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import {
  CustomFormControl,
  NumericTextField,
} from '../../../components/CustomInput';
import {
  FilledButton,
  OutlinedButton,
  StandardButton,
} from '../../../components/UI/Button';
import { Constants } from '../../../constants/configuration';
import { IconAddPhotoAlternateMS, IconEditMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { validateHexColor } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useUploadFile from '../../../hooks/useUploadFile';
import { PageTitle } from '../styles';
import {
  BoldTypography,
  ColorBox,
  ColorContainer,
  ConfigurationsObs,
  GridContainer,
  LogoCardMedia,
  StyledTextField,
  SwitchTypography,
  Upload,
  UploadIcon,
  UploadSubtitle,
  UploadText,
} from './styles';

export default function Customize(): JSX.Element {
  const [file, setFile] = useState<File>();
  const [urlImage, setUrlImage] = useState('');
  const [color, setColor] = useState<string>('');
  const [dealine, setDeadline] = useState<number>(7);
  const [deadlineWarning, setDeadlineWarning] = useState<number>(3);
  const [allowDeadline, setAllowDeadline] = useState<boolean>(false);
  const [updatePrevious, setUpdatePrevious] = useState<boolean>(false);

  const { dragActive, handleDrag, handleDrop, handleFileUpload } =
    useUploadFile();
  const {
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    setUpdateLogo,
    updateLogo,
    company,
  } = useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await companyAPI.getSelfCompany();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data.evaluation_primary_color) {
        setColor(response.data.evaluation_primary_color);
      }
      if (response.data.logo) {
        setUrlImage(response.data.logo);
      }
      if (response.data.default_settings.work_orders_deadline_in_days) {
        setDeadline(
          response.data.default_settings.work_orders_deadline_in_days
        );
      }
      if (response.data.default_settings.work_orders_warning_deadline_in_days) {
        setDeadlineWarning(
          response.data.default_settings.work_orders_warning_deadline_in_days
        );
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  useEffect(() => {
    if (company?.show_work_orders_deadline_warning) {
      setAllowDeadline(company.show_work_orders_deadline_warning);
    }
  }, [company]);

  const dropFile = (e: DragEvent<HTMLDivElement>): void => {
    setUrlImage('');
    const file = handleDrop(e);
    if (file) {
      setFile(file[0]);
    }
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): void => {
    setUrlImage('');
    const file = handleFileUpload(e);
    if (file) {
      setFile(file[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!validateHexColor(color)) {
      setSnackbarMessage('Cor inválida');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const deadlineData = {
      work_orders_deadline_in_days: dealine,
      work_orders_warning_deadline_in_days: deadlineWarning,
      show_work_orders_deadline_warning: allowDeadline,
    };

    const formData = new FormData();
    if (file) {
      formData.append('logo', file);
    }
    formData.append('evaluation_primary_color', color);

    try {
      const response = await companyAPI.updateCompanyEvaluationConfig(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const deadlineResponse = await companyAPI.updateDeadline(
        deadlineData,
        updatePrevious
      );

      if (deadlineResponse.detail.description) {
        throw new Error(deadlineResponse.detail.description);
      }

      if (deadlineResponse.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Relatório personalizado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (file) {
        setUpdateLogo(!updateLogo);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <Box>
      <PageTitle>{Constants.customize}</PageTitle>
      <BoldTypography>{Constants.logo}</BoldTypography>
      <Box component="form" id="customize-report" onSubmit={handleSubmit}>
        {urlImage.length > 0 ? (
          <Box mb="20px">
            <LogoCardMedia image={urlImage} title="logo" />
            <label htmlFor="uploadLogo">
              <input
                accept="image/*"
                id="uploadLogo"
                type="file"
                style={{ display: 'none' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFile(e)}
              />
              {!dragActive && (
                <StandardButton component="span">
                  {IconEditMS}
                  {Constants.changeLogo}
                </StandardButton>
              )}
            </label>
          </Box>
        ) : (
          <Box>
            {file ? (
              <Box mb="40px">
                <LogoCardMedia image={URL.createObjectURL(file)} title="logo" />
                <label htmlFor="uploadLogo">
                  <input
                    accept="image/*"
                    id="uploadLogo"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      uploadFile(e)
                    }
                  />
                  {!dragActive && (
                    <StandardButton component="span">
                      {IconEditMS}
                      {Constants.changeLogo}
                    </StandardButton>
                  )}
                </label>
              </Box>
            ) : (
              <Upload
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={dropFile}
              >
                <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
                <UploadText>
                  {dragActive ? Constants.dragText : Constants.uploadText}
                </UploadText>
                <UploadSubtitle>{Constants.fileType}</UploadSubtitle>
                <label htmlFor="uploadPictures">
                  <input
                    accept="image/*"
                    id="uploadPictures"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      uploadFile(e)
                    }
                  />
                  {!dragActive && (
                    <StandardButton component="span">
                      {IconAddPhotoAlternateMS}
                      {Constants.searchLogo}
                    </StandardButton>
                  )}
                </label>
              </Upload>
            )}
          </Box>
        )}
        <ConfigurationsObs>{Constants.logoObs}</ConfigurationsObs>
        <Divider />
        <BoldTypography>{Constants.color}</BoldTypography>
        <Typography>{Constants.primary}</Typography>
        <ColorContainer>
          <ColorBox
            required
            type="color"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
            bgcolor={`${color}`}
          />
          <StyledTextField
            required
            id="color"
            inputProps={{ maxLength: 7 }}
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
        </ColorContainer>
        <ConfigurationsObs>{Constants.colorObs}</ConfigurationsObs>
        <Divider />
        <BoldTypography>{Constants.deadlineTitle}</BoldTypography>
        <CustomFormControl
          label="habilitar prazo"
          isChecked={allowDeadline}
          setIsChecked={setAllowDeadline}
        />
        <Box my={3}>
          <Grid container spacing={4} mb={3}>
            <Grid item xs={1.5}>
              <NumericTextField
                id="deadline"
                label={Constants.deadline}
                suffix=""
                maxLength={3}
                value={dealine}
                setValue={setDeadline}
                disable={!allowDeadline}
              />
            </Grid>
            <Grid item xs={2.5}>
              <NumericTextField
                id="warning"
                label={Constants.deadlineWarning}
                suffix=""
                maxLength={3}
                value={deadlineWarning}
                setValue={setDeadlineWarning}
                disable={!allowDeadline}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <SwitchTypography
              isSelected={!updatePrevious}
              isDisabled={!allowDeadline}
            >
              {Constants.updateAll}
            </SwitchTypography>
            <Switch
              disabled={!allowDeadline}
              checked={updatePrevious}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setUpdatePrevious(e.target.checked);
              }}
            />
            <SwitchTypography
              isSelected={updatePrevious}
              isDisabled={!allowDeadline}
            >
              {Constants.updateNew}
            </SwitchTypography>
          </Stack>
        </Box>
        <Divider />
      </Box>
      <GridContainer container textAlign="center">
        <Grid item xs={4}>
          <OutlinedButton>{Constants.cancel}</OutlinedButton>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <FilledButton id="save-btn" type="submit" form="customize-report">
            {Constants.save}
          </FilledButton>
        </Grid>
      </GridContainer>
    </Box>
  );
}
