import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledIcon = styled(Typography)(() => ({
  span: {
    fontSize: 100,
  },
  marginBottom: '30px',
}));

export const BoldText = styled(Typography)(() => ({
  fontSize: '32px',
  textAlign: 'center',
  fontWeight: 600,
  marginBottom: '30px',
}));

export const UnavailableArea = styled(Typography)(() => ({
  fontSize: '24px',
  textAlign: 'center',
  span: {
    fontWeight: 600,
  },
}));

export const InputBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  gap: '1rem',
  margin: '36px 0',
}));

export const ButtonBox = styled(Box)(() => ({
  width: '100%',
  margin: '20px 0',
  display: 'flex',
  gap: '1rem',
  justifyContent: 'space-around',
}));

export const PatternFormatStyle = {
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: '#004D43',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
};
