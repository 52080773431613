/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, Button, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { sampleAPI } from '../../../../api';
import { EvaluationType } from '../../../../api/enumerations';
import {
  deleteErrorReport,
  updateErrorReport,
} from '../../../../api/errorReport';
import { ElementData } from '../../../../api/sample/types';
import {
  AvmFactorsData,
  AvmInferenceData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { MultilineTextField } from '../../../../components/CustomInput';
import { AccordionTitle } from '../../../../components/Sections/AccordionTitle';
import { ClientData } from '../../../../components/Sections/ClientData';
import { FormResponse } from '../../../../components/Sections/FormResponse';
import { PdfViewer } from '../../../../components/Sections/PdfViewer';
import { PropertyAddress } from '../../../../components/Sections/PropertyAddress';
import { PropertyData } from '../../../../components/Sections/PropertyData';
import { PropertyInfo } from '../../../../components/Sections/PropertyInfo';
import { ReportInfo } from '../../../../components/Sections/ReportInfo';
import { Rooms } from '../../../../components/Sections/Rooms';
import { SampleSection } from '../../../../components/Sections/Sample';
import { ScheduleInfo } from '../../../../components/Sections/ScheduleInfo';
import { Title } from '../../../../components/Sections/Title';
import { ToogleAccordion } from '../../../../components/ToogleAccordion';
import { Constants } from '../../../../constants/errorReport';
import {
  IconApartmentMS,
  IconCalendarTodayMS,
  IconDescriptionMS,
  IconEditDocumentMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { useAccordion } from '../../../../hooks/useAccordion';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import { useRooms } from '../../../../hooks/useRooms';
import { BoxContainer, SectionBox, SectionTitle, SubmitBox } from '../styles';

interface RevisionProps {
  propertyData: WorkOrderData;
  errorFindings: string | undefined;
}

export function Revision({
  propertyData,
  errorFindings,
}: RevisionProps): JSX.Element {
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [hasAvmReport, setHasAvmReport] = useState(false);
  const [findings, setFindings] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const reportId = parseInt(id as string, 10);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const { getErrorMessage } = useErrorMessage();
  const { handleRooms, rooms } = useRooms();

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven &&
      expandEight &&
      expandNine
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
    expandEight,
    expandNine,
  ]);

  const getDataCallback = useCallback(async () => {
    let getSamples = false;
    let isFactors = false;

    const avmData = propertyData.avm_report as
      | AvmInferenceData
      | AvmFactorsData
      | null;

    if (avmData) {
      setHasAvmReport(true);
    }

    if (propertyData.samples) {
      getSamples = true;
    }

    if (
      propertyData.evaluation_type === EvaluationType.AUTOFACTORS ||
      propertyData.evaluation_type === EvaluationType.SIMPFACTORS
    ) {
      isFactors = true;
    }

    if (propertyData.inspection) {
      handleRooms(propertyData.inspection.id);
    }

    if (getSamples) {
      try {
        const response = await sampleAPI.getSample(propertyData.id, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        if (isFactors) {
          const filteredSamples: ElementData[] = response.data.items.filter(
            (data) => data.is_utilized
          );
          setSampleData(filteredSamples);
          return;
        }
        setSampleData(response.data.items);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, []);

  useEffect(() => {
    if (errorFindings) {
      setFindings(errorFindings);
    }
    setExpandNine(true);
    getDataCallback();
  }, [getDataCallback]);

  const handleUpdateReport = async (): Promise<void> => {
    if (findings.length < 3) {
      setSnackbarMessage('Adicione observações!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    const reportData = {
      findings,
      finished: true,
    };

    try {
      const response = await updateErrorReport(reportId, reportData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Relatório atualizado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setIsUpdated(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleDeleteReport = async (): Promise<void> => {
    try {
      const response = await deleteErrorReport(reportId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Erro reportado finalizado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigate('/error-report');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <BoxContainer>
      <Title
        osNumber={0}
        newOs
        title={Constants.revision}
        createdAt={propertyData?.created_at}
      />
      <ClientData propertyData={propertyData} showProgressBar={false} />
      <Box>
        <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
        <SectionBox>
          <AccordionTitle
            title={Constants.propertyData}
            icon={IconApartmentMS}
            openAccordion={expandOne}
            setOpenAccordion={setExpandOne}
          />
          {expandOne && <PropertyData propertyData={propertyData} />}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.propertyAddress}
            icon={IconLocationOnMS}
            openAccordion={expandTwo}
            setOpenAccordion={setExpandTwo}
          />
          {expandTwo && <PropertyAddress propertyData={propertyData} />}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.propertyDetails}
            icon={IconLocationCityMS}
            openAccordion={expandThree}
            setOpenAccordion={setExpandThree}
          />
          {expandThree && <PropertyInfo propertyData={propertyData} />}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.scheduleInfo}
            icon={IconCalendarTodayMS}
            openAccordion={expandFour}
            setOpenAccordion={setExpandFour}
          />
          {expandFour && (
            <ScheduleInfo
              inspectionData={propertyData.inspection}
              rooms={rooms}
            />
          )}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.propertyPhotos}
            icon={IconPhotoLibraryMS}
            openAccordion={expandFive}
            setOpenAccordion={setExpandFive}
          />
          {expandFive && (
            <Box>
              {propertyData?.inspection && rooms.length > 0 ? (
                <Rooms
                  // eslint-disable-next-line max-len
                  navigationPath={`${propertyData.id}/inspection/${propertyData.inspection.id}/rooms`}
                  rooms={rooms}
                  osId={propertyData.id}
                  inspectionId={propertyData.inspection.id}
                />
              ) : (
                <Typography>{Constants.noPhotos}</Typography>
              )}
            </Box>
          )}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.form}
            icon={IconListAltMS}
            openAccordion={expandSix}
            setOpenAccordion={setExpandSix}
          />
          {expandSix && (
            <FormResponse
              questionForm={propertyData?.inspection?.form_response}
            />
          )}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.sample}
            icon={IconFactCheckMS}
            openAccordion={expandSeven}
            setOpenAccordion={setExpandSeven}
          />
          {expandSeven && (
            <Box>
              {sampleData && sampleData?.length > 0 ? (
                <SampleSection
                  sampleData={sampleData}
                  propertyData={propertyData}
                />
              ) : (
                <Typography>{Constants.noSample}</Typography>
              )}
            </Box>
          )}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.report}
            icon={IconEngineeringMS}
            openAccordion={expandEight}
            setOpenAccordion={setExpandEight}
          />
          {expandEight && (
            <Box>
              {hasAvmReport && propertyData ? (
                <ReportInfo propertyData={propertyData} />
              ) : (
                <Box>{Constants.noReport}</Box>
              )}
            </Box>
          )}
        </SectionBox>
        <SectionBox>
          <AccordionTitle
            title={Constants.pdfReport}
            icon={IconDescriptionMS}
            openAccordion={expandNine}
            setOpenAccordion={setExpandNine}
          />
          {expandNine && (
            <PdfViewer
              osId={propertyData.id}
              samplePdf={propertyData.samples_report_pdf}
            />
          )}
        </SectionBox>
        <SectionBox>
          <SectionTitle mb="20px">
            {IconEditDocumentMS}
            {Constants.findings}
          </SectionTitle>
          <MultilineTextField
            id="findings"
            label="observações"
            value={findings}
            setValue={setFindings}
          />
        </SectionBox>
        <SubmitBox>
          {isUpdated ? (
            <Button onClick={handleDeleteReport}>{Constants.finish}</Button>
          ) : (
            <Button onClick={handleUpdateReport}>{Constants.save}</Button>
          )}
        </SubmitBox>
      </Box>
    </BoxContainer>
  );
}
