import { useContext, useState } from 'react';

import { roomAPI } from '../api';
import { RoomData } from '../api/rooms/types';
import { GlobalContext } from '../context/global';
import { getErrorMessage } from '../helpers';

interface UseRoomsProps {
  handleRooms: (id: number) => Promise<void>;
  rooms: RoomData[];
}
export function useRooms(): UseRoomsProps {
  const [rooms, setRooms] = useState<RoomData[]>([]);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const reorderRoomArray = (arr: RoomData[], word: string): RoomData[] => {
    const startWithWord = arr.filter((item) =>
      item.name.toLowerCase().includes(word)
    );
    const withoutWord = arr.filter(
      (item) => !item.name.toLowerCase().includes(word)
    );
    return [...startWithWord, ...withoutWord];
  };

  const handleRooms = async (id: number): Promise<void> => {
    try {
      const response = await roomAPI.getAllRooms(id, 1, 50);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== 0) {
        throw new Error('Não foi possível carregar os cômodos.');
      }
      if (response.data) {
        let reorder = reorderRoomArray(response.data, 'fachada');
        reorder = reorder.slice(0, 3);
        setRooms(reorder);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };
  return {
    handleRooms,
    rooms,
  };
}
