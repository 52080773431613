import { CircularProgress, TextField } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { potencialCustomerAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { CustomTextField } from '../../../components/CustomInput';
import { FilledButton, OutlinedButton } from '../../../components/UI/Button';
import { Constants } from '../../../constants/createAccount';
import { IconPersonPinPlace } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import {
  cleanValue,
  formatPhone,
  getErrorMessage,
  validateCellphone,
  validateEmail,
  validatePhone,
  validateUserName,
} from '../../../helpers';
import {
  BoldText,
  ButtonBox,
  InputBox,
  PatternFormatStyle,
  StyledIcon,
  UnavailableArea,
} from './styles';

interface PotencialCustomerProps {
  city: string;
  uf: number;
  cep: string;
}

export default function PotencialCustomer({
  city,
  uf,
  cep,
}: PotencialCustomerProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  const navigateToLogin = (): void => navigate('/login');

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    const trimEmail = email.trim();
    const phone = cleanValue(contact);

    if (!validateUserName(customerName)) {
      setSnackbarMessage('Nome inválido');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setSnackbarMessage('Email inválido');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    if (
      phone.length < 10 ||
      (phone.length === 10 && !validatePhone(phone)) ||
      (phone.length === 11 && !validateCellphone(phone))
    ) {
      setSnackbarMessage('Contato inválido, adicione um número válido.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoading(false);
      return;
    }

    const data = {
      city,
      uf,
      email: trimEmail,
      name: customerName,
      phone_number: phone,
      zipcode: cep,
    };

    try {
      const response = await potencialCustomerAPI.registerPotencialCustomer(
        data
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Email cadastrado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setLoading(false);
      navigate('/login');
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoading(false);
    }
  };

  return (
    <>
      <StyledIcon color="primary">{IconPersonPinPlace}</StyledIcon>
      <BoldText>{Constants.network}</BoldText>
      {Constants.unavailableArea.map((line) => (
        <UnavailableArea key={line} className="paragraph">
          {line
            .split('*')
            .map((text, index) =>
              index % 2 === 0 ? text : <span key={text}>{text}</span>
            )}
        </UnavailableArea>
      ))}
      <InputBox component="form" id="register-email" onSubmit={handleSubmit}>
        <CustomTextField
          required
          id="customer-name"
          label={Constants.name}
          value={customerName}
          setValue={setCustomerName}
        />
        <CustomTextField
          required
          id="email"
          label={Constants.email}
          value={email}
          setValue={setEmail}
        />
        <TextField
          required
          id="cellphone"
          label={Constants.telephone}
          color="secondary"
          onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
            e.target.setCustomValidity('Preencha este campo')
          }
          onInput={(e: ChangeEvent<HTMLInputElement>) =>
            e.target.setCustomValidity('')
          }
          inputProps={{ maxLength: 15 }}
          value={contact}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setContact(formatPhone(e.target.value))
          }
          sx={PatternFormatStyle}
        />
      </InputBox>
      <ButtonBox>
        <OutlinedButton onClick={navigateToLogin}>
          {Constants.later}
        </OutlinedButton>
        <FilledButton form="register-email" type="submit" disabled={loading}>
          {loading ? (
            <CircularProgress color="inherit" size={22} />
          ) : (
            Constants.registerEmail
          )}
        </FilledButton>
      </ButtonBox>
    </>
  );
}
