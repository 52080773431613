import { Grid } from '@mui/material';

import {
  convertClientKind,
  convertEvaluationType,
} from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { IconPersonMS } from '../../../constants/icons';
import {
  selectPropertyUse,
  selectReportFunction,
  selectReportGoal,
} from '../../../constants/selectOptions';
import { CustomTextField, SelectTextField } from '../../CustomInput';
import { SectionBox } from '../../UI/Box';
import { InputTitle, SectionTitle, StyledInput } from '../../UI/Typography';
import { Constants } from './constants';

interface ClientDataEditProps {
  propertyData: WorkOrderData;
  client: string;
  setClient: (value: string) => void;
  solicitor: string;
  setSolicitor: (value: string) => void;
  goal: number;
  setGoal: (value: number) => void;
  propertyFunction: number;
  setPropertyFunction: (value: number) => void;
  propertyUse: number;
  setPropertyUse: (value: number) => void;
}

export function ClientDataEdit({
  propertyData,
  client,
  setClient,
  solicitor,
  setSolicitor,
  goal,
  setGoal,
  propertyFunction,
  setPropertyFunction,
  propertyUse,
  setPropertyUse,
}: ClientDataEditProps): JSX.Element {
  return (
    <SectionBox>
      <SectionTitle mb="20px">
        {IconPersonMS}
        {Constants.clientData}
      </SectionTitle>
      <Grid container spacing={4}>
        <Grid item xs={6} xl={4}>
          <CustomTextField
            required
            id="client"
            label="proprietário do imóvel"
            value={client}
            setValue={setClient}
          />
        </Grid>
        <Grid item xs={6} xl={4}>
          <CustomTextField
            id="solicitor"
            label="solicitante"
            value={solicitor}
            setValue={setSolicitor}
          />
        </Grid>
        <Grid item xs={6} xl={4}>
          <SelectTextField
            id="os-goal"
            label="objetivo"
            value={goal}
            setValue={setGoal}
            selectOptions={selectReportGoal()}
          />
        </Grid>
        <Grid item xs={6} xl={3}>
          <InputTitle>{Constants.evaluationType}</InputTitle>
          <StyledInput>
            {convertEvaluationType(propertyData.evaluation_type)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} xl={3}>
          <InputTitle>{Constants.clientType}</InputTitle>
          <StyledInput>
            {convertClientKind(propertyData.client_kind)}
          </StyledInput>
        </Grid>
        <Grid item xs={4} xl={3}>
          <SelectTextField
            id="os-function"
            label="finalidade"
            value={propertyFunction}
            setValue={setPropertyFunction}
            selectOptions={selectReportFunction()}
          />
        </Grid>
        <Grid item xs={4} xl={3}>
          <SelectTextField
            id="property-use"
            label="uso do imóvel"
            value={propertyUse}
            setValue={setPropertyUse}
            selectOptions={selectPropertyUse()}
          />
        </Grid>
      </Grid>
    </SectionBox>
  );
}
