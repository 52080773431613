import {
  Box,
  ButtonBase,
  CardMedia,
  Skeleton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccordionRoomTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '26px',
  fontWeight: 600,
  marginBottom: '20px',
  color: theme.palette.common.black,
  textTransform: 'capitalize',
  cursor: 'pointer',
  '& span': { color: theme.palette.primary.main },
}));

export const GalleryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '32px',
  margin: '14px 0 52px 0',
  [theme.breakpoints.down('xl')]: {
    gap: '20px',
  },
}));

export const PhotoContainer = styled(Box)(() => ({
  borderRadius: '30px',
  cursor: 'pointer',
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  objectFit: 'cover',
  width: '325px',
  height: '230px',
  borderRadius: '30px',
  [theme.breakpoints.down('xl')]: {
    width: '260px',
    height: '190px',
  },
}));

export const FlexReverseBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  position: 'relative',
}));

interface SelectCheckboxProps {
  ischecked: boolean;
}

export const SelectCheckbox = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'ischecked',
})<SelectCheckboxProps>(({ theme, ischecked }) => {
  return {
    width: 25,
    height: 25,
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize: 16,
    color: ischecked ? 'white' : 'black',
    transition: 'background-color 0.3s, border-color 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '15px',
    right: '15px',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
    span: {
      fontWeight: 600,
      color: theme.palette.common.black,
    },
    ...(ischecked && {
      backgroundColor: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    }),
  };
});

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: '#E6E6E6',
  height: '230px',
  width: '325px',
  borderRadius: '30px',
  [theme.breakpoints.down('xl')]: {
    width: '260px',
    height: '190px',
  },
}));
