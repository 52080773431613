/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { companyAPI } from '../../api';
import { StatusCode } from '../../api/enumerations';
import logo from '../../assets/images/proto-logo.png';
import { CustomPatternFormat } from '../../components/CustomInput';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/createAccount';
import { GlobalContext } from '../../context/global';
import { RegistrationIdentificationContext } from '../../context/registrationIdentification';
import {
  cleanValue,
  getErrorMessage,
  getRegistrationUfPosition,
} from '../../helpers';
import { removeVerifiedCep, setVerifiedCep } from '../../helpers/cepVerified';
import {
  removeLocalStorageCompany,
  removeLocalStorageToken,
} from '../../helpers/localStorage';
import PotencialCustomer from './PotencialCustomer';
import {
  Container,
  InputBox,
  Title,
  Logo,
  StyledText,
  SubmitButton,
} from './styles';

export default function CreateAccount(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [unavailableCep, setUnavailableCep] = useState(false);
  const [potencialCustomerData, setPotencialCustomerData] = useState({
    city: '',
    uf: 0,
    cep: '',
  });
  const [cep, setCep] = useState('');

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { reset } = useContext(RegistrationIdentificationContext);

  const navigate = useNavigate();

  useEffect(() => {
    removeLocalStorageToken();
    removeLocalStorageCompany();
    reset();
    removeVerifiedCep();
  }, []);

  const navigateToLogin = (): void => navigate('/login');

  const verifyCep = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    const cepValue = cleanValue(cep);
    if (cepValue.length < 8) {
      setSnackbarMessage('CEP inválido');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    try {
      const { detail, data } = await companyAPI.checkCoverage(cepValue);
      if (detail.status_code === StatusCode.NOT_FOUND) {
        setUnavailableCep(true);
      }

      if (detail.city && detail.uf) {
        setPotencialCustomerData({
          city: detail.city,
          uf: getRegistrationUfPosition(detail.uf),
          cep: cepValue,
        });
      }

      if (detail.description) {
        throw new Error(detail.description);
      }

      if (data) {
        setVerifiedCep(cep);
        setLoading(false);
        navigate('/create-account/register');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      setLoading(false);
    }
  };
  return (
    <Container>
      <Logo onClick={navigateToLogin}>
        <img src={logo} alt="Logo do Proto" />
      </Logo>
      <Title>
        {unavailableCep ? Constants.unavailable : Constants.welcome}
      </Title>
      {unavailableCep ? (
        <InputBox padding="30px 92px">
          <PotencialCustomer
            city={potencialCustomerData.city}
            uf={potencialCustomerData.uf}
            cep={potencialCustomerData.cep}
          />
        </InputBox>
      ) : (
        <InputBox
          component="form"
          id="verify-cep"
          onSubmit={verifyCep}
          padding="90px 92px"
        >
          <StyledText>{Constants.verifyCEP}</StyledText>
          <Box>
            <CustomPatternFormat
              required
              minLength={8}
              id="cep"
              label={Constants.cep}
              value={cep}
              setValue={setCep}
              format="#####-###"
            />
          </Box>
          <SubmitButton form="verify-cep" type="submit" disabled={loading}>
            {loading ? (
              <CircularProgress color="inherit" size={22} />
            ) : (
              Constants.confirm
            )}
          </SubmitButton>
        </InputBox>
      )}
      {openSnackbar && <Snackbar />}
    </Container>
  );
}
