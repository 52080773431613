import { Box, ButtonBase, Paper, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PipelineBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  minWidth: '300px',
  width: '300px',
  textAlign: 'center',
}));

export const PipelineTitle = styled(ButtonBase)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.common.white,
  height: '56px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#000',
  fontWeight: '600',
  fontSize: 16,
  position: 'relative',
}));

interface SortIconsProps {
  selectedchild: 'asc' | 'desc' | 'none';
}

export const SortIcons = styled(Box)<SortIconsProps>(
  ({ theme, selectedchild }) => {
    const inactiveColor = '#8D9295';
    const activeColor = theme.palette.primary.dark;

    return {
      height: '26px',
      position: 'absolute',
      right: 12,
      display: 'flex',
      span: {
        fontSize: 16,
        fontWeight: 600,
        transition: theme.transitions.create('color', {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.standard,
        }),
      },
      'span:nth-of-type(1)': {
        alignSelf: 'end',
        marginRight: -8,
        color:
          selectedchild === 'asc' || selectedchild === 'none'
            ? inactiveColor
            : activeColor,
      },
      'span:nth-of-type(2)': {
        color:
          selectedchild === 'desc' || selectedchild === 'none'
            ? inactiveColor
            : activeColor,
      },
    };
  }
);

export const PipelinePaper = styled(Paper)(({ theme }) => ({
  height: '90%',
  overflowY: 'auto',
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  padding: '8px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  boxShadow: 'none',
}));

export const StyledSkeleton = styled(Skeleton)(() => ({
  borderRadius: 5,
  width: '100%',
  backgroundColor: '#E6E6E6',
  margin: '8px 0 16px',
  padding: '16px',
  height: 230,
}));
