import { Grid } from '@mui/material';

import { WorkOrderData } from '../../../api/workOrders/types';
import {
  selectConservation,
  selectStandard,
  selectStandard400K,
} from '../../../constants/selectOptions';
import { NumericTextField, SelectTextField } from '../../CustomInput';
import { RoundedButton } from '../../UI/Button';
import { Constants } from './constants';

interface ApproveConservationProps {
  propertyData: WorkOrderData;
  disableInfo: boolean;
  age: number;
  setAge: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  handleApproveConservation(): Promise<void>;
}

export function ApproveConservation({
  propertyData,
  disableInfo,
  age,
  setAge,
  conservation,
  setConservation,
  buildingStandard,
  setBuildingStandard,
  handleApproveConservation,
}: ApproveConservationProps): JSX.Element {
  return (
    <Grid container spacing={4} mt="2px">
      <Grid item xs={3.5}>
        <NumericTextField
          disable={disableInfo}
          id="property-age-estimate"
          label="idade do imóvel"
          suffix=" anos"
          maxLength={9}
          value={age}
          setValue={setAge}
        />
      </Grid>
      <Grid item xs={8} />
      <Grid item xs={3.5}>
        <SelectTextField
          disable={disableInfo}
          id="conservation-age-estimate"
          label="estado de conservação do imóvel"
          value={conservation}
          setValue={setConservation}
          selectOptions={selectConservation()}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectTextField
          disable={disableInfo}
          id="standard-age-estimate"
          label="padrão construtivo"
          value={buildingStandard}
          setValue={setBuildingStandard}
          selectOptions={
            propertyData?.sample_sell_price_above_400k
              ? selectStandard400K()
              : selectStandard()
          }
        />
      </Grid>
      <Grid item xs={3}>
        <RoundedButton
          model="filled"
          width="sm"
          disabled={disableInfo}
          onClick={handleApproveConservation}
        >
          {disableInfo ? Constants.approved : Constants.approve}
        </RoundedButton>
      </Grid>
    </Grid>
  );
}
