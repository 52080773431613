import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color: theme.palette.primary.main,
}));

export const DialogTitle = styled(Typography)(({ theme }) => ({
  '& span': { color: theme.palette.primary.main },
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '24px',
  fontWeight: '600',
  margin: '4px 0 20px',
  textTransform: 'capitalize',
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  '& span': { color: theme.palette.primary.main },
}));

export const InputTitle = styled(Typography)(() => ({
  paddingBottom: '4px',
  fontSize: '14px',
}));

export const StyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '180px',
  paddingLeft: '8px',
  minHeight: '28px',
  '&.reason': {
    fontWeight: 400,
    border: 0,
    color: '#FF334B',
    padding: 0,
  },
}));

export const SmallStyledInput = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '18px',
  borderBottom: '1px solid #8B8C8E',
  inlineSize: 'fit-content',
  minWidth: '100px',
  paddingLeft: '8px',
  minHeight: '28px',
}));

export const WhiteCircularProgress = styled(CircularProgress)(() => ({
  color: '#FFFFFF',
}));
