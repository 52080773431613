/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  NumericFormat,
  PatternFormat,
  PatternFormatProps,
} from 'react-number-format';

import { cleanValue, convertToFloatNumber } from '../../helpers';

interface CustomTextFieldProps {
  id: string;
  label?: string;
  value: string;
  setValue?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: boolean;
  type?: string;
  onChange?: (value: boolean) => void;
}

export function CustomTextField({
  id,
  label,
  value,
  setValue,
  disabled,
  placeholder,
  maxLength,
  required,
  error,
  type,
  onChange,
}: CustomTextFieldProps): JSX.Element {
  const handleInvalid = (event: ChangeEvent<HTMLInputElement>): void => {
    if (required) {
      event.target.setCustomValidity('Preencha este campo');
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
    if (required) {
      event.target.setCustomValidity('');
    }
  };
  return (
    <TextField
      type={type}
      id={id}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      color="secondary"
      value={value}
      inputProps={{ maxLength }}
      error={error}
      onInvalid={handleInvalid}
      onInput={handleInput}
      onChange={(e) => {
        if (setValue) {
          setValue(e.target.value);
        }
        if (onChange) {
          onChange(true);
        }
      }}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': { borderRadius: '16px' },
        'input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
        },
      }}
    />
  );
}

interface SelectTextFieldProps {
  id: string;
  label: string | JSX.Element;
  value: number;
  setValue: (value: number) => void;
  selectOptions: { value: number; label: string }[];
  disable?: boolean;
  required?: boolean;
  allSelectable?: boolean;
}

export function SelectTextField({
  id,
  label,
  value,
  setValue,
  selectOptions,
  disable,
  required,
  allSelectable,
}: SelectTextFieldProps): JSX.Element {
  return (
    <TextField
      id={id}
      disabled={disable || false}
      select
      required={required}
      label={label}
      color="secondary"
      value={value}
      SelectProps={{
        IconComponent: ExpandMoreIcon,
      }}
      onChange={(e) => {
        setValue(Number(e.target.value));
      }}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          borderRadius: '16px',
        },
        '& .MuiSvgIcon-root': {
          color: (theme) => theme.palette.primary.main,
          fontSize: '2rem',
        },
      }}
    >
      {selectOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{
            '&:hover': { backgroundColor: '#d485eed1' },
            ...(!allSelectable && {
              '&:first-of-type': {
                color: '#7c7c7c',
                '&.Mui-selected': { backgroundColor: '#EDEDED' },
                '&:hover': {
                  backgroundColor: '#EDEDED',
                  cursor: 'auto',
                },
              },
            }),
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

interface MultilineTextFieldProps {
  id: string;
  label?: string;
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
}

export function MultilineTextField({
  id,
  label,
  value,
  setValue,
  placeholder,
}: MultilineTextFieldProps): JSX.Element {
  return (
    <TextField
      id={id}
      multiline
      rows={6}
      label={label}
      color="secondary"
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    />
  );
}

interface NumericTextFieldProps {
  required?: boolean;
  id: string;
  label: string;
  suffix: string;
  decimalSeparator?: string;
  decimalScale?: number;
  maxLength: number;
  value: number;
  setValue: (value: number) => void;
  disable?: boolean;
  error?: boolean;
}

export function NumericTextField({
  required,
  id,
  label,
  suffix,
  decimalSeparator,
  decimalScale,
  maxLength,
  value,
  setValue,
  disable,
  error,
}: NumericTextFieldProps): JSX.Element {
  return (
    <NumericFormat
      required={required}
      customInput={TextField}
      disabled={disable || false}
      error={error || false}
      id={id}
      label={label}
      color="secondary"
      suffix={suffix}
      decimalSeparator={decimalSeparator || '.'}
      decimalScale={decimalScale || 0}
      allowNegative={false}
      inputProps={{ maxLength }}
      value={value}
      onChange={(e) => {
        const convertedValue = convertToFloatNumber(e.target.value);
        setValue(convertedValue || 0);
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    />
  );
}

interface PreffixNumericTextFieldProps {
  required?: boolean;
  id: string;
  label: string;
  prefix: string;
  decimalSeparator?: string;
  decimalScale?: number;
  maxLength: number;
  value: number;
  setValue: (value: number) => void;
}

export function PreffixNumericTextField({
  required,
  id,
  label,
  prefix,
  decimalSeparator,
  decimalScale,
  maxLength,
  value,
  setValue,
}: PreffixNumericTextFieldProps): JSX.Element {
  return (
    <NumericFormat
      required={required}
      customInput={TextField}
      id={id}
      label={label}
      color="secondary"
      prefix={prefix}
      decimalSeparator={decimalSeparator || '.'}
      decimalScale={decimalScale || 0}
      thousandsGroupStyle="thousand"
      thousandSeparator=" "
      allowNegative={false}
      inputProps={{ maxLength }}
      value={value}
      onChange={(e) => {
        const convertedValue = convertToFloatNumber(e.target.value);
        setValue(convertedValue);
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    />
  );
}

interface CustomFormControlProps {
  required?: boolean;
  label: string;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
}

export function CustomFormControl({
  required,
  label,
  isChecked,
  setIsChecked,
}: CustomFormControlProps): JSX.Element {
  return (
    <FormControlLabel
      id="form-control"
      required={required}
      label={label}
      control={
        <Checkbox
          color="primary"
          checked={isChecked}
          onChange={(event) => {
            setIsChecked(event.target.checked);
          }}
        />
      }
      sx={{
        '&:hover': {
          '& .MuiButtonBase-root': {
            color: '#d485eed1',
          },
          '& .MuiTypography-root': {
            fontWeight: '600',
          },
        },
      }}
    />
  );
}

interface PathologiesFormControlProps {
  label: string;
  value: number;
  isChecked: boolean;
  onChangeCallback: (checked: boolean, value: number) => void;
  disabled: boolean;
}

export function PathologiesFormControl({
  label,
  value,
  isChecked,
  onChangeCallback,
  disabled,
}: PathologiesFormControlProps): JSX.Element {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          disabled={disabled}
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
            onChangeCallback(event.target.checked, value);
          }}
        />
      }
      sx={{
        '&:hover': {
          '& .MuiButtonBase-root': {
            color: (theme) => theme.palette.primary.main,
          },
          '& .MuiTypography-root': {
            fontWeight: '600',
          },
        },
      }}
      label={label}
    />
  );
}

interface StyledSelectProps {
  id: string;
  label: string;
  value: string;
  setValue: (value: string) => void;
  selectOptions: string[];
  error: boolean;
  setError: (value: boolean) => void;
}

export function StyledSelect({
  id,
  label,
  value,
  setValue,
  selectOptions,
  error,
  setError,
}: StyledSelectProps): JSX.Element {
  return (
    <TextField
      id={id}
      select
      label={label}
      color="secondary"
      value={value}
      error={error || false}
      SelectProps={{
        IconComponent: ExpandMoreIcon,
      }}
      onChange={(e) => {
        setValue(e.target.value);
        setError(false);
      }}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          borderRadius: '16px',
        },
        '& .MuiSvgIcon-root': {
          color: (theme) => theme.palette.primary.main,
          fontSize: '2rem',
        },
      }}
    >
      <MenuItem
        disabled
        value="selecione uma opção"
        sx={{
          color: '#000000',
          fontStyle: 'normal',
        }}
      >
        <Typography>selecione uma opção</Typography>
      </MenuItem>
      {selectOptions.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            '&:hover': { backgroundColor: '#d485eed1' },
          }}
        >
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

interface CustomPatternFormatProps extends PatternFormatProps {
  id: string;
  label: string;
  value: string;
  setValue?: (value: string) => void;
  helper?: any;
  minLength?: number;
  format: string;
  disabled?: boolean;
}

export function CustomPatternFormat({
  id,
  label,
  value,
  setValue,
  required,
  minLength,
  format,
  helper,
  disabled,
}: CustomPatternFormatProps): JSX.Element {
  const [error, setError] = useState(false);
  const handleInvalid = (event: ChangeEvent<HTMLInputElement>): void => {
    if (required) {
      event.target.setCustomValidity('Preencha este campo');
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
    if (required) {
      event.target.setCustomValidity('');
    }
  };

  return (
    <PatternFormat
      id={id}
      customInput={TextField}
      label={label}
      required={required}
      format={format}
      color="secondary"
      mask="_"
      value={value}
      onInvalid={handleInvalid}
      onInput={handleInput}
      error={value.length > 0 && error}
      disabled={disabled}
      onChange={(e) => {
        if (setValue) {
          setValue(e.target.value);
        }
        if (helper) {
          helper(e.target.value);
        }
        if (!!minLength && cleanValue(e.target.value).length < minLength) {
          setError(true);
        } else {
          setError(false);
        }
      }}
      sx={{ width: '100%', '& .MuiInputBase-root': { borderRadius: '16px' } }}
    />
  );
}

interface DisabledTextFieldProps {
  id: string;
  label: string;
  value: string;
}

export function DisabledTextField({
  id,
  label,
  value,
}: DisabledTextFieldProps): JSX.Element {
  return (
    <TextField
      id={id}
      label={label}
      disabled
      color="secondary"
      value={value}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': { borderRadius: '16px' },
        'input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
        },
      }}
    />
  );
}

interface AlphaNumericTexfieldProps {
  id: string;
  label: string;
  value: string;
  setValue: (value: string) => void;
  maxLength: number;
  required?: boolean;
  cauFormat?: boolean;
}

export function AlphaNumericTextfield({
  id,
  label,
  value,
  setValue,
  maxLength,
  required,
  cauFormat,
}: AlphaNumericTexfieldProps): JSX.Element {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    let formattedValue = inputValue;

    if (cauFormat && inputValue.length > 6) {
      formattedValue = `${inputValue.slice(0, 6)}-${inputValue.slice(6, 7)}`;
    }

    setValue(formattedValue);
  };
  return (
    <TextField
      id={id}
      label={label}
      required={required}
      color="secondary"
      value={value}
      inputProps={{ maxLength }}
      onChange={handleInputChange}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': { borderRadius: '16px' },
        'input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
        },
      }}
    />
  );
}
