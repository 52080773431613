/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import dayjs, { Dayjs } from 'dayjs';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { inspectionAPI, userAPI, workOrderAPI } from '../../../api';
import { EvaluationType, Status, StatusCode } from '../../../api/enumerations';
import { CalendarScheduleData } from '../../../api/inspections/types';
import { UserData } from '../../../api/users/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import { GlobalContext } from '../../../context/global';
import {
  cleanValue,
  formatTime,
  getErrorMessage,
  validateCellphone,
  validateFiles,
  validatePhone,
  validateUserName,
} from '../../../helpers';
import { useAttachment } from '../../../hooks/useAttachmentFiles';
import { useChangeStatus } from '../../../hooks/useChangeStatus';
import useGeneral from '../../../hooks/useGeneral';
import { usePropertyData } from '../../../hooks/usePropertyData';
import useSearchCep from '../../../hooks/useSearchCep';

type LatLngLiteral = google.maps.LatLngLiteral;

interface ScheduleHook {
  propertyData: WorkOrderData | undefined;
  engineer: number;
  setEngineer: (value: number) => void;
  usersData: UserData[] | undefined;
  responsibleName: string;
  setResponsibleName: (value: string) => void;
  contact: string;
  setContact: (value: string) => void;
  date: Dayjs | null;
  setDate: (value: Dayjs | null) => void;
  startTime: Dayjs;
  endTime: Dayjs;
  hasSchedule: boolean;
  scheduledData: CalendarScheduleData[] | undefined;
  address: string;
  setAddress: (value: string) => void;
  addressNumber: string;
  setAddressNumber: (value: string) => void;
  age: number;
  setAge: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  builtArea: number;
  setBuiltArea: (value: number) => void;
  cep: string;
  setCep: (value: string) => void;
  city: string;
  setCity: (value: string) => void;
  client: string;
  setClient: (value: string) => void;
  complement: string;
  setComplement: (value: string) => void;
  concept: number;
  setConcept: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  district: string;
  setDistrict: (value: string) => void;
  goal: number;
  setGoal: (value: number) => void;
  judicialDistrict: string;
  setJudicialDistrict: (value: string) => void;
  parkingLots: number;
  setParkingLots: (value: number) => void;
  propertyFunction: number;
  setPropertyFunction: (value: number) => void;
  propertyType: number;
  setPropertyType: (value: number) => void;
  propertyUse: number;
  setPropertyUse: (value: number) => void;
  downloadIptu: string;
  downloadRegister: string;
  registerNumber: string;
  setRegisterNumber: (value: string) => void;
  registrationUf: number;
  setRegistrationUf: (value: number) => void;
  propertyRooms: number;
  setPropertyRooms: (value: number) => void;
  solicitor: string;
  setSolicitor: (value: string) => void;
  suites: number;
  setSuites: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  totalArea: number;
  setTotalArea: (value: number) => void;
  uf: string;
  setUf: (value: string) => void;
  zone: number;
  setZone: (value: number) => void;
  registerFileName: string;
  iptuFileName: string;
  searchMap: boolean;
  setSearchMap: (value: boolean) => void;
  setIsAddressEdited: (value: boolean) => void;
  pinPlace: LatLngLiteral;
  setPinPlace: (value: LatLngLiteral) => void;
  enableEditing: boolean;
  setEnableEditing: (value: boolean) => void;
  today: string[];
  getEngineerEvents: (id: number, date: string) => Promise<void>;
  handleStartTime: (value: Dayjs | null) => void;
  handleEndTime: (value: Dayjs | null) => void;
  handleSchedule: (edit: boolean) => Promise<void>;
  handleScheduleExist: () => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  verifyCep: (cep: string) => Promise<void>;
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void>;
  handleDeleteSchedule(id: string): Promise<void>;
  submitLoading: boolean;
  loadingPage: boolean;
  loadingApprove: boolean;
  setLoadingApprove: (value: boolean) => void;
  completeScheduling: boolean;
}

const useSchedule = (): ScheduleHook => {
  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [engineer, setEngineer] = useState(0);
  const [responsibleName, setResponsibleName] = useState('');
  const [contact, setContact] = useState('');
  const [date, setDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs>(dayjs());
  const [endTime, setEndTime] = useState<Dayjs>(dayjs());
  const [formattedStartHour, setFormattedStartHour] = useState('');
  const [formattedEndHour, setFormattedEndHour] = useState('');
  const [scheduledData, setScheduledData] = useState<CalendarScheduleData[]>();
  const [hasSchedule, setHasSchedule] = useState(false);
  const [scheduleId, setScheduleId] = useState(0);
  const [enableEditing, setEnableEditing] = useState(false);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);

  // Client data
  const [client, setClient] = useState('');
  const [solicitor, setSolicitor] = useState('');
  const [goal, setGoal] = useState(0);
  const [propertyFunction, setPropertyFunction] = useState(0);
  const [propertyUse, setPropertyUse] = useState(0);

  // Property data
  const [registerFileName, setRegisterFileName] = useState('');
  const [iptuFileName, setIptuFileName] = useState('');
  const [registerNumber, setRegisterNumber] = useState('');
  const [propertyType, setPropertyType] = useState(0);
  const [concept, setConcept] = useState(0);
  const [zone, setZone] = useState(0);
  const [judicialDistrict, setJudicialDistrict] = useState('');
  const [registrationUf, setRegistrationUf] = useState(0);
  const [downloadIptu, setDownloadIptu] = useState('');
  const [downloadRegister, setDownloadRegister] = useState('');

  // Property address
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [cep, setCep] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [searchMap, setSearchMap] = useState(true);
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>({
    lat: -23.56162,
    lng: -46.65591,
  });

  // Property informations
  const [totalArea, setTotalArea] = useState(0);
  const [builtArea, setBuiltArea] = useState(0);
  const [propertyRooms, setPropertyRooms] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [suites, setSuites] = useState(0);
  const [parkingLots, setParkingLots] = useState(0);
  const [age, setAge] = useState(0);
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [conservation, setConservation] = useState(0);

  const [isAddressEdited, setIsAddressEdited] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [completeScheduling, setCompleteScheduling] = useState(false);

  const today = new Date().toISOString().split('T');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { iptuLink, setIptuLink, registerLink, setRegisterLink } =
    useAttachment();
  const { loadingApprove, setLoadingApprove, handleStatus } = useChangeStatus();
  const { osId } = useGeneral();
  const { propertyData, getDataCallback } = usePropertyData({
    status: Status.SCHEDULE,
  });
  const { handleSearchCep } = useSearchCep();

  const getEngineerEvents = async (
    engId: number,
    date: string
  ): Promise<void> => {
    const newDate = dayjs(date);
    const startDate = newDate.startOf('week').format('YYYY-MM-DD');
    const endDate = newDate.endOf('week').format('YYYY-MM-DD');
    const url = `&users_ids=${engId}`;

    try {
      const response = await inspectionAPI.getAllInspections(
        startDate,
        endDate,
        url
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setScheduledData(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const getScheduleData = (): void => {
    if (propertyData) {
      if (propertyData.inspection) {
        setHasSchedule(true);
        setEngineer(propertyData.inspection.engineer_id);
        setScheduleId(propertyData.inspection.id);
        setResponsibleName(propertyData.inspection.responsible);
        setContact(propertyData.inspection.responsible_phone);
        setDate(dayjs(propertyData.inspection.schedule_at));
        setFormattedStartHour(propertyData.inspection.start_at);
        setFormattedEndHour(propertyData.inspection.finish_at);
        setStartTime(
          dayjs(
            `${propertyData.inspection.schedule_at}T${propertyData.inspection.start_at}`
          )
        );
        setEndTime(
          dayjs(
            `${propertyData.inspection.schedule_at}T${propertyData.inspection.finish_at}`
          )
        );
        getEngineerEvents(
          propertyData.inspection.engineer_id,
          propertyData.inspection.schedule_at
        );
      }
      setClient(propertyData.client_name);
      setSolicitor(propertyData.requester_name);
      setGoal(propertyData.report_goal);
      setPropertyFunction(propertyData.report_function);
      setPropertyUse(propertyData.use);

      setRegisterNumber(propertyData.registration_number);
      setPropertyType(propertyData.real_estate_kind);
      setConcept(propertyData.communities_kind);
      setZone(propertyData.zone);
      setJudicialDistrict(propertyData.judicial_district);
      setRegistrationUf(propertyData.uf);
      setDownloadIptu(propertyData.iptu_document || '');
      setDownloadRegister(propertyData.real_estate_registry || '');

      setAddress(propertyData.street);
      setAddressNumber(propertyData.number);
      setComplement(propertyData.address_complement);
      setCep(propertyData.zip_code);
      setDistrict(propertyData.district);
      setCity(propertyData.city);
      setUf(propertyData.state);
      if (propertyData.latitude && propertyData.longitude) {
        setPinPlace({
          lat: Number(propertyData.latitude),
          lng: Number(propertyData.longitude),
        });
      }

      setTotalArea(propertyData.total_area);
      setBuiltArea(propertyData.constructed_area);
      setPropertyRooms(propertyData.bedrooms);
      setToilets(propertyData.bathrooms);
      setSuites(propertyData.suites);
      setParkingLots(propertyData.parking_spaces);
      setAge(propertyData.age);
      setBuildingStandard(propertyData.constructive_standard);
      setConservation(propertyData.preservation_state);

      setLoadingPage(false);
    }
  };

  const getEngineers = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    try {
      const response = await userAPI.GetAllUsers(page, 10);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const activeEngineers = response.data.filter(
        (engineer) => engineer.is_active
      );

      setUsersData([...usersData, ...activeEngineers]);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page]);

  useEffect(() => {
    getScheduleData();
    getEngineers();
  }, [propertyData, page]);

  useEffect(() => {
    if (propertyData) {
      getDataCallback();
    }
  }, [iptuLink, registerLink]);

  const handleSchedule = async (editSchedule: boolean): Promise<void> => {
    if (engineer === 0) {
      setSnackbarMessage('Selecione um vistoriador.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (!validateUserName(responsibleName)) {
      setSnackbarMessage('O nome do responsável é obrigatório.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (!date || !formattedStartHour || !formattedEndHour) {
      setSnackbarMessage('Os campos de data e hora são obrigatórios.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const phone = cleanValue(contact);

    if (
      phone.length < 10 ||
      (phone.length === 10 && !validatePhone(phone)) ||
      (phone.length === 11 && !validateCellphone(phone))
    ) {
      setSnackbarMessage('Contato inválido, adicione um número válido.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const scheduleData = {
      schedule_at: dayjs(date).format('YYYY-MM-DD'),
      start_at: formattedStartHour,
      finish_at: formattedEndHour,
      responsible: responsibleName,
      responsible_phone: phone,
      engineer_id: engineer,
      work_order_id: osId,
    };

    if (editSchedule) {
      try {
        const response = await inspectionAPI.updateInspections(
          scheduleId,
          scheduleData
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Agendamento alterado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        getEngineerEvents(engineer, scheduleData.schedule_at);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    } else {
      try {
        const response = await inspectionAPI.createInspection(scheduleData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Agendamento realizado com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        getEngineerEvents(engineer, scheduleData.schedule_at);
        setHasSchedule(true);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    }
  };

  const handleStartTime = (newValue: Dayjs | null): void => {
    if (newValue) {
      const endTime = dayjs(newValue).add(1, 'hours');
      setStartTime(newValue);
      setEndTime(endTime);
      setFormattedStartHour(formatTime(newValue));
      setFormattedEndHour(formatTime(endTime));
    }
  };

  const handleEndTime = (newValue: Dayjs | null): void => {
    if (newValue) {
      setEndTime(newValue);
      setFormattedEndHour(formatTime(newValue));
    }
  };

  const handleScheduleExist = (): void => {
    if (
      !hasSchedule &&
      (propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
        propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES)
    ) {
      setSnackbarMessage(
        'É necessário adicionar um agendamento para aprovar esta OS.'
      );
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoadingApprove(false);
    } else {
      handleStatus(osId, propertyData?.reference_number);
    }
  };

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setUf(addressData.uf);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
    }
    setIsAddressEdited(true);
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: string
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];

    const formData = new FormData();
    if (fileType === 'register') {
      formData.append('real_estate_registry', file);
    }
    if (fileType === 'iptu') {
      formData.append('iptu_document', file);
    }

    try {
      const response = await workOrderAPI.editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (fileType === 'register') {
        setRegisterLink(file.name);
        setRegisterFileName(file.name);
      }
      if (fileType === 'iptu') {
        setIptuLink(file.name);
        setIptuFileName(file.name);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!cep) {
      setSnackbarMessage('Adicione a localização do imóvel');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (isAddressEdited) {
      setSnackbarMessage('Pesquise a localização no mapa antes de salvar.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (buildingStandard === 0 || conservation === 0) {
      setSnackbarMessage('Os dados de seleção são obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (
      concept === 0 ||
      goal === 0 ||
      propertyFunction === 0 ||
      propertyType === 0 ||
      propertyUse === 0 ||
      registrationUf === 0
    ) {
      setSnackbarMessage('Os dados de seleção são obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const formData = new FormData();
    formData.append('street', address);
    formData.append('number', addressNumber);
    formData.append('age', age.toString());
    formData.append('constructive_standard', buildingStandard.toString());
    formData.append('constructed_area', builtArea.toString());
    formData.append('zip_code', cep);
    formData.append('city', city);
    formData.append('client_name', client);
    formData.append('address_complement', complement);
    formData.append('communities_kind', concept.toString());
    formData.append('preservation_state', conservation.toString());
    formData.append('district', district);
    formData.append('report_goal', goal.toString());
    formData.append('judicial_district', judicialDistrict);
    formData.append('parking_spaces', parkingLots.toString());
    formData.append('report_function', propertyFunction.toString());
    formData.append('real_estate_kind', propertyType.toString());
    formData.append('use', propertyUse.toString());
    formData.append('registration_number', registerNumber);
    formData.append('requester_name', solicitor);
    formData.append('uf', registrationUf.toString());
    formData.append('bedrooms', propertyRooms.toString());
    formData.append('suites', suites.toString());
    formData.append('bathrooms', toilets.toString());
    formData.append('total_area', totalArea.toString());
    formData.append('state', uf);
    formData.append('zone', zone.toString());
    formData.append('latitude', pinPlace.lat.toString());
    formData.append('longitude', pinPlace.lng.toString());

    try {
      setSubmitLoading(true);
      const response = await workOrderAPI.editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setSubmitLoading(false);
      setEnableEditing(false);
      getDataCallback();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  const resetInputs = (): void => {
    setHasSchedule(false);
    setEngineer(0);
    setScheduleId(0);
    setResponsibleName('');
    setContact('');
    setDate(null);
    setFormattedStartHour('');
    setFormattedEndHour('');
    setStartTime(dayjs());
    setEndTime(dayjs());
    setCompleteScheduling(false);
  };

  const handleDeleteSchedule = async (id: string): Promise<void> => {
    const intId = Number(id);
    try {
      const response = await inspectionAPI.deleteInspection(intId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Ops! Algo deu errado.');
      }

      if (response.detail.status_code === StatusCode.OK) {
        setSnackbarMessage('Agendamento deletado!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setScheduledData(undefined);
        resetInputs();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (hasSchedule) {
      setCompleteScheduling(true);
    } else {
      setCompleteScheduling(false);
    }
  }, [hasSchedule]);

  return {
    propertyData,
    engineer,
    setEngineer,
    usersData,
    responsibleName,
    setResponsibleName,
    contact,
    setContact,
    date,
    setDate,
    startTime,
    endTime,
    hasSchedule,
    scheduledData,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    setCep,
    city,
    setCity,
    client,
    setClient,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyType,
    setPropertyType,
    propertyUse,
    setPropertyUse,
    downloadIptu,
    downloadRegister,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    solicitor,
    setSolicitor,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    registerFileName,
    iptuFileName,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    enableEditing,
    setEnableEditing,
    today,
    getEngineerEvents,
    handleStartTime,
    handleEndTime,
    handleSchedule,
    handleScheduleExist,
    handleSubmit,
    verifyCep,
    handleFileUpload,
    handleDeleteSchedule,
    submitLoading,
    loadingPage,
    loadingApprove,
    setLoadingApprove,
    completeScheduling,
  };
};

export default useSchedule;
