import { Grid } from '@mui/material';

import { PropertyType } from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { NumericTextField } from '../../CustomInput';
import { InputTitle, SmallStyledInput } from '../../UI/Typography';
import { Constants } from './constants';

interface PropertyInfoEditProps {
  propertyData: WorkOrderData;
  propertyRooms: number;
  setPropertyRooms: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  suites: number;
  setSuites: (value: number) => void;
}

export function PropertyInfoEdit({
  propertyData,
  propertyRooms,
  setPropertyRooms,
  toilets,
  setToilets,
  suites,
  setSuites,
}: PropertyInfoEditProps): JSX.Element {
  return (
    <Grid container spacing={4} className="conservation-state">
      <Grid item xs={2}>
        <InputTitle>
          {propertyData.real_estate_kind === PropertyType.APARTMENT
            ? Constants.totalArea
            : Constants.landArea}
        </InputTitle>
        <SmallStyledInput>{propertyData.total_area}</SmallStyledInput>
      </Grid>
      <Grid item xs={2}>
        <InputTitle>
          {propertyData.real_estate_kind === PropertyType.APARTMENT
            ? Constants.privateArea
            : Constants.constructedArea}
        </InputTitle>
        <SmallStyledInput>{propertyData.constructed_area}</SmallStyledInput>
      </Grid>
      <Grid item xs={2}>
        <NumericTextField
          id="rooms"
          label="quartos"
          suffix=" quartos"
          maxLength={12}
          value={propertyRooms}
          setValue={setPropertyRooms}
        />
      </Grid>
      <Grid item xs={2}>
        <NumericTextField
          id="toilets"
          label="banheiros"
          suffix=" banheiros"
          maxLength={13}
          value={toilets}
          setValue={setToilets}
        />
      </Grid>
      <Grid item xs={2}>
        <NumericTextField
          id="suites"
          label="suítes"
          suffix=" suítes"
          maxLength={10}
          value={suites}
          setValue={setSuites}
        />
      </Grid>
      <Grid item xs={2}>
        <InputTitle>{Constants.parkingLots}</InputTitle>
        <SmallStyledInput>{propertyData.parking_spaces}</SmallStyledInput>
      </Grid>
    </Grid>
  );
}
