/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { SelectedPictures } from '..';

import { pictureAPI } from '../../../../api';
import { GalleryPictureData } from '../../../../api/pictures/types';
import { RoomData } from '../../../../api/rooms/types';
import {
  IconExpandMoreMS,
  IconKeyboardArrowRightMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import {
  AccordionRoomTitle,
  FlexReverseBox,
  GalleryContainer,
  PhotoContainer,
  SelectCheckbox,
  StyledCardMedia,
  StyledSkeleton,
} from './styles';

interface AccordionRoomProps {
  roomData: RoomData;
  handleSelectPhoto: (picture: GalleryPictureData, roomId?: number) => void;
  selectedPictures: SelectedPictures[];
  setSelectedPictures: Dispatch<SetStateAction<SelectedPictures[]>>;
  loading: boolean;
}

export function AccordionRoom({
  roomData,
  handleSelectPhoto,
  selectedPictures,
  setSelectedPictures,
  loading,
}: AccordionRoomProps): JSX.Element {
  const [openAccordion, setOpenAccordion] = useState(true);
  const [inspectionPictures, setInspectionPictures] = useState<
    GalleryPictureData[]
  >([]);
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [loadingPictures, setLoadingPictures] = useState(true);

  const picturesPerPage = 12;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const { navigateHome } = useGeneral();

  const { inspection } = useParams();

  const handleIsChecked = (picId: number): boolean => {
    if (roomData.room_type_id === 10) return true;
    if (selectedPictures.some((el) => el.id === picId)) {
      return true;
    }
    return false;
  };

  const swapSelectedIndex = (
    arr: SelectedPictures[],
    id: number,
    newIndex: number
  ): SelectedPictures[] => {
    const newArr = [...arr];
    const currentObjIndex = newArr.findIndex((obj) => obj.id === id);
    if (currentObjIndex === -1) {
      return arr;
    }

    if (newArr[currentObjIndex].selected_index === newIndex) {
      return arr;
    }

    const targetObjIndex = newArr.findIndex(
      (obj) => obj.selected_index === newIndex
    );
    if (targetObjIndex === -1) {
      return arr;
    }

    const temp = newArr[currentObjIndex].selected_index;
    newArr[currentObjIndex].selected_index =
      newArr[targetObjIndex].selected_index;
    newArr[targetObjIndex].selected_index = temp;

    return newArr;
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }
    try {
      const roomID = roomData.room_type_id;
      const response = await pictureAPI.getAllPictures(
        roomData.id,
        page,
        picturesPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (roomID === 10 && response.data.length > 0 && !loading) {
        const picture = response.data[0];

        if (selectedPictures.length === 0 && inspection) {
          handleSelectPhoto(picture);

          const selectedPicturesData = {
            inspection_id: Number(inspection),
            selected_pictures: [{ id: picture.id, selected_index: 1 }],
          };
          try {
            const response = await pictureAPI.selectInspectionPictures(
              selectedPicturesData
            );

            if (response.detail.description) {
              throw new Error(response.detail.description);
            }
          } catch (err) {
            setSnackbarMessage('Algo deu errado, tente novamente.');
            setErrorMessage(true);
            setOpenSnackbar(true);
          }
        } else if (selectedPictures.length > 0) {
          setSelectedPictures((prevState) =>
            swapSelectedIndex(prevState, picture.id, 1)
          );
        }
      }

      setInspectionPictures([...inspectionPictures, ...response.data]);
      setLoadingPictures(false);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateHome();
    }
  }, [page, openAccordion, selectedPictures]);

  useEffect(() => {
    if (openAccordion) {
      getDataCallback();
    }
  }, [page, openAccordion]);

  useEffect(() => {
    if (!loading && selectedPictures.length) {
      swapSelectedIndex(selectedPictures, 1, 1);
    }
  }, [selectedPictures]);

  return (
    <Box>
      <AccordionRoomTitle
        onClick={() => {
          setOpenAccordion(!openAccordion);
        }}
      >
        {roomData.name}
        {openAccordion ? IconExpandMoreMS : IconKeyboardArrowRightMS}
      </AccordionRoomTitle>
      {openAccordion && (
        <GalleryContainer>
          {loadingPictures ? (
            [1, 2, 3, 4].map((item) => (
              <StyledSkeleton
                key={item}
                variant="rectangular"
                animation="pulse"
              />
            ))
          ) : inspectionPictures.length > 0 ? (
            inspectionPictures.map((pic) => (
              <PhotoContainer
                key={pic.id}
                onClick={() => handleSelectPhoto(pic, roomData.room_type_id)}
              >
                <FlexReverseBox>
                  <SelectCheckbox
                    id={String(pic.id)}
                    ischecked={handleIsChecked(pic.id)}
                  >
                    {selectedPictures.map((photo) => (
                      <Typography key={photo.id}>
                        {photo.id === pic.id ? photo.selected_index : ''}
                      </Typography>
                    ))}
                  </SelectCheckbox>
                </FlexReverseBox>
                <StyledCardMedia image={pic.file} />
              </PhotoContainer>
            ))
          ) : (
            <Typography>sem imagens</Typography>
          )}
        </GalleryContainer>
      )}
    </Box>
  );
}
